<div >
      @if (currentPage === 'topic') {
        <h1 class="header1 black f-60 t-space">
        {{adam_labels.acount_settings_module.topicTitle}}</h1>
      }
      @if (currentPage === 'notification') {
        <h1 class="header1 black f-60 t-space-notif">
        {{adam_labels.acount_settings_module.alertsTitle}}</h1>
      }
      @if (currentPage === 'changePassword') {
        <app-reset-password-container
        (isUserSetupNext)="isUserSetupContinue('changePassword')"></app-reset-password-container>
      }
      <!--<app-topics  *ngIf="currentPage === 'topic'" (isUserSetupNext)="isUserSetupContinue($event)"></app-topics>-->
      <!-- <app-notification *ngIf="currentPage === 'notification'"
    (isUserSetupNext)="isUserSetupContinue($event)"></app-notification> -->
    @if (currentPage === 'login') {
      <app-home (isUserSetupNext)="isUserSetupContinue('login')"></app-home>
    }
 
</div>