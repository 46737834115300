<div [ngClass]="customClass" class="footer d-flex justify-content-between">
    <div class="footer-left ">
        {{adam_labels.common.footerStrt}} {{currentYear}}{{adam_labels.common.footerEnd}}
    </div>
    <div class="footer-right ">
        <a class="links" [routerLink]="'/customer-support'" *ngIf="!isOps">{{adam_labels.footer_module.footer1}}</a>
        <a class="links text-with-dot" [routerLink]="'/privacy-policy'">{{adam_labels.footer_module.footer2}}</a>
        <a class="links text-with-dot" [routerLink]="'/legal-notice'">{{adam_labels.footer_module.footer3}}</a>
        <a class="links text-with-dot" [routerLink]="'/pricing-disclosure'">{{adam_labels.footer_module.footer4}}</a>
    </div>
</div>
