import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { SearchProductsApiResponse, SearchQueryParam } from '../../core/models/header.model';
import { SearchServiceService } from '../search-bar/search-service.service';
import { StorageService } from '../storage.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';
import { Location } from '@angular/common';


@Component({
  selector: 'app-search-results',
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
   InfiniteScrollComponent
    ],
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss'
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  showResults = true;
  searchQueryObj: any = {};
  predictedResult: any = {};
  cols: any[];
  searchHistory: any[] = [];
  currentPageIndex = 1;
  searchSubscription: Subscription;
  resultDetails: any = {
    searchResultCount: 0,
    productsCount: 0,
    pageSize: 0
  };
  searchResultList: any = [];
  adam_labels: any;

  constructor(
    private route: ActivatedRoute,
    private searchServiceService: SearchServiceService,
    private authService: AuthService,
    private storageService: StorageService,
    private location: Location
  ) { }

  public ngOnInit(): void {
    this.adam_labels = AdamConf;
    this.cols = [
      { field: 'dispencingUnitName', header: this.adam_labels.search_results_module.header1 },
      { field: 'coreName', header: this.adam_labels.search_results_module.header2 },
      { field: 'quantity', header: this.adam_labels.search_results_module.header3 },
      { field: 'stackNumber', header: this.adam_labels.search_results_module.header4 }
    ];

    this.route.paramMap.subscribe(searchParam => {
      if (searchParam) {
        this.searchQueryObj.query = searchParam.get('searchQuery');
        this.searchQueryObj.status = searchParam.get('selectedStatus');
        this.getSearchResults(this.searchQueryObj, this.currentPageIndex);
      }
    });

    this.searchSubscription = this.searchServiceService.getSearchObs().subscribe(data => {
      if (data !== 1) {
        if (this.searchQueryObj.query !== data.data || this.searchQueryObj.status !== data.status) {
          this.searchQueryObj.query = data.data;
          this.searchQueryObj.status = data.status;
          this.updateSearchHistory();
          this.currentPageIndex = 1;
          this.getSearchResults(this.searchQueryObj, this.currentPageIndex);
        }
      }
    });
    this.updateSearchHistory();
  }

  public onScroll(): void {
    if (this.resultDetails.productsCount / (this.resultDetails.pageSize * this.currentPageIndex) > 1) {
      this.currentPageIndex++;
      this.getSearchResults(this.searchQueryObj, this.currentPageIndex);
    }
  }
  resetForm(){
    this.location.back()
  }
  private updateSearchHistory(): void {
    if (JSON.parse(this.storageService.getItem('searchHistory'))) {
      this.searchHistory = JSON.parse(this.storageService.getItem('searchHistory'));
    }
    if (typeof this.searchQueryObj.query !== 'undefined') {
      const currentSearchObj = {
        source: 'history',
        data: this.searchQueryObj.query.trim()
      };
      if (this.searchHistory.length === 0) {
        this.searchHistory = [currentSearchObj, ...this.searchHistory];
        this.storageService.setItem('searchHistory', JSON.stringify(this.searchHistory));
      } else {
        if (!this.searchHistory.some(q => q.data === this.searchQueryObj.query)) {
          this.searchHistory = [currentSearchObj, ...this.searchHistory.slice(0, AdamConf.SEARCH_HISTORY_COUNT - 1)];
          this.storageService.setItem('searchHistory', JSON.stringify(this.searchHistory));
        }
      }
    }
  }

  private getSearchResults(searchQuery: SearchQueryParam, pageIndex: number): void {
    let hostName = null;
    const userData = this.authService.getUserData();
    hostName = this.storageService.getItem('hostName');
    if (userData === null || userData === undefined) {
      this.searchServiceService.getProductResults(searchQuery, pageIndex, hostName)
      .subscribe((results: SearchProductsApiResponse) => {
        if (results.responseCode === '2001') {
          this.getProductListSuccessHandler(results);
        } else if (results.responseCode === '4002') {
          this.updateOnNoResults();
        }
      });
    } else {
      this.searchServiceService.getProductResultsAuthorized(searchQuery, pageIndex)
      .subscribe((results: SearchProductsApiResponse) => {
        if (results.responseCode === '2001') {
          this.getProductListSuccessHandler(results);
        } else if (results.responseCode === '4002') {
          this.updateOnNoResults();
        }
      });
    }
    
  }

  private getProductListSuccessHandler(results: SearchProductsApiResponse): void {
    if (results.responseData.locationDetails.length > 0) {
      this.resultDetails.searchResultCount = results.responseData.searchResultsCount;
      this.resultDetails.productsCount = results.responseData.productsCount;
      this.resultDetails.pageSize = results.responseData.pageSize;
      if (this.currentPageIndex === 1) {
        this.searchResultList = [];
      }
      const newList = results.responseData.locationDetails
        .map(result => {
          result.details.forEach(detail => {
            detail.unit = detail.coreName + ', ' + detail.dispencingUnitName;
          });
          result.details.sort((a, b) => {
            return a.stackNumber.localeCompare(b.stackNumber);
          });
          return result;
        });
      this.searchResultList = this.searchResultList
        .concat(newList);
      this.showResults = true;
    } else {
      this.updateOnNoResults();
      if (results.responseData.suggestion != null) {
        this.predictedResult.data = results.responseData.suggestion.toLowerCase().split(' ').map((data) => {
          return data.replace(data[0], data[0].toUpperCase());
        }).join(' ');
      } else {
        this.predictedResult.data = '';
      }
    }
  }

  private updateOnNoResults(): void {
    this.resultDetails.searchResultCount = 0;
    this.searchResultList = {};
    this.showResults = false;
  }

  public showPredictedResult(event: any): void {
    event.preventDefault();
    this.searchServiceService.setSearchObs({ data: this.predictedResult.data, status: this.searchQueryObj.status });
  }

  public ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.searchServiceService.setSearchObs(1);
  }

}
