import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Router, RouterModule } from '@angular/router';
import { AdamConf } from '../../app.config';
import { UtilityService } from '../../shared/utility.service';


import { TokenApiResponse, LoginAPiResponse } from '../models/login.model';
import { StorageService } from '../storage.service';
import { AuthService } from '../../auth/auth.service';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http'; // Import HttpClient directly
import { ChangeDetectorRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, RouterModule, MatIconModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  signedIn = false;
  user: any;
  greeting: any = '';
  public variableData: string = '';

  hide = true;
  submitted: boolean = false;
  loginForm: FormGroup = new FormGroup({});
  loginFormVal: { email: string; password: string };
  invalidUserOrPwd: boolean = false;
  loginProgress = false;
  alreadyLogged = false;
  routerLink: string;
  isUserSetup: boolean = false;
  adam_labels: any;
  signInErrorMsg: any;
  private isMobileFlag: boolean = false;
  requestOrigin: string = '';
  @Output() notifySignUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() notifyForgot: EventEmitter<void> = new EventEmitter<void>();


  constructor(
    private router: Router,
    private readonly utilityService: UtilityService,
    readonly authService: AuthService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    const userData = this.authService.getCurrentUser();
    this.alreadyLogged = userData && userData.userId ? true : false;
   
    this.routerLink = '';
  }


  ngOnInit() {
    this.requestOrigin = this.storageService.getItem('hostName') ?? '';
    this.adam_labels = AdamConf;
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.isMobileFlag = this.utilityService.isMobile();

    this.routerLink = this.router.routerState.snapshot.url;
    if (this.routerLink.includes("/complete-registration-login")) {
      this.isUserSetup = true;
    } else {
      this.isUserSetup = false;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  validateForm() {
    let valid = true;
    if (this.loginForm.value.email === "") {
      this.loginForm.controls['email'].setErrors({ required: true });
      valid = false;
    }
   
    if (this.loginForm.value.email !== "") {
      const regEx = new RegExp(/^(?!.*?[._\-@]{2})(?!^\.)(?!^\_)(?!^\-)(?!.*[-_.]$)[A-Za-z0-9_.-]+@[A-Za-z0-9.]+\.[A-Za-z]+$/);
      if (!regEx.test(this.loginForm.value.email)) {
        this.loginForm.controls['email'].setErrors({ pattern: true });
        valid = false;
      }
    }
    if (this.loginForm.value.password === "") {
      this.loginForm.controls['password'].setErrors({ required: true });
      valid = false;
    }
    return valid;
  }

  onLoginSubmit() {
    //check for existing session
    if (this.authService.getCurrentUser()) {
      //aleady have session so redirect to dashboard.
      window.location.href = "/dashboard";
    } else {
      this.onLoginSubmitConf();
      if (this.loginForm.invalid === false) {
        const queryParams = {
          "email": this.loginFormVal.email.toUpperCase(),
          "password": this.loginFormVal.password,
          "requestOrigin": this.requestOrigin
        };
        this.authService.login(queryParams).subscribe(
          (response: LoginAPiResponse) => {
            if (response.responseCode === "2001") {
              const queryParams1 = {
                "username": this.loginFormVal.email.toUpperCase(),
                "password": this.loginFormVal.password,
                "grant_type": "password"
              };
              this.authService.authorizeUser(queryParams1).subscribe(
                (authorizeUserResponse: TokenApiResponse) => {
                  this.storageService.setItem('currentUser', JSON.stringify(authorizeUserResponse));
                  this.authService.setSession(authorizeUserResponse).then(() => {
                    this.authService.getAutoRefreshPreferences().subscribe((autoRefreshApiResponse) => {
                      if (autoRefreshApiResponse.responseCode === '2001') {
                        this.authService.addAutoRefreshPreferenceInSession(autoRefreshApiResponse.responseData);
                      }
                    });
                    this.authService.setUserPermissionData().then(() => {
                      if (this.isMobileFlag === true) {
                        this.router.navigate(['inventories/hand-held-scanner/replenishment-summary']);
                      } else {
                        this.router.navigate(['dashboard']);
                       
                      }
                    });
                  });
                },
                () => {
                  this.authService.logout().then(() => {
                    this.router.navigate(['login']);
                  });
                }
              );
            } else if (response.responseCode == "4002") {
              if (response.responseMessage == "Your password has expired. Please reset your password.") {
                this.router.navigate(['password-expired/' + this.loginFormVal.email]);
                this.variableData=response.responseData;
                console.log('data:',this.variableData);
                this.sendData(this.variableData)
              } else {
                this.signInErrorMsg = response.responseMessage;
                this.signInError();
              }
            }
          }
        );
      }
      
    }
  }

  signInFn(response: { challengeName?: string; signInUserSession?: unknown; attributes?: { email: string } }) {
   

    //reset pwd challange
    if (response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      this.router.navigate(['/complete-registration/' + this.loginFormVal.email + '/' + this.loginFormVal.password]);
    }
    // success login
    else if (response.signInUserSession) {
      this.router.navigate(['dashboard']);
    }
  }

  signInError() {
    this.loginProgress = false;
    this.loginForm.enable();
    this.invalidUserOrPwd = true;

  }

  onLoginSubmitConf() {
    this.submitted = true;
    this.invalidUserOrPwd = false;
    if (!this.validateForm()) {
      return false;
    }
    this.loginFormVal = this.loginForm.value;
    this.loginProgress = true;
    this.loginForm.disable();
    return true; // Add a return statement
  }
  gotoForgotPwd() {
    this.notifyForgot.emit();
    this.router.navigate(['forgot-password']);
  }
  sendData(param:string) {
    this.authService.changeData(param);
    console.log('inside sendata:',param);
    
  }
  resetForm() {
    this.loginForm.reset();
    this.submitted = false;
    this.invalidUserOrPwd = false;
    this.router.navigate(['home']);
  }

  requestAccess() {
    this.router.navigate(['request-access']);
  }

  
}

