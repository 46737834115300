import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { DomainGuard } from './auth/domain.guard';
import { CustomerSupportComponent } from './core/footer/customer-support/customer-support.component';
import { PrivacyPolicyComponent } from './core/footer/privacy-policy/privacy-policy.component';
import { LegalNoticeComponent } from './core/footer/legal-notice/legal-notice.component';
import { PricingDisclosureComponent } from './core/footer/pricing-disclosure/pricing-disclosure.component';
// import { LoginComponent } from './core/login/login.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { MaterialsManagementContainerComponent } from './core/dashboard/materials-management/materials-management-container/materials-management-container.component';
import { ClinicalContainerComponent } from './core/dashboard/clinical/clinical-container/clinical-container.component';
// import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { SearchResultsComponent } from './core/search-results/search-results.component';
import { NotificationListComponent } from './core/notification/notification-list/notification-list.component';
import { UserSetupComponent } from './core/user-setup/user-setup.component';
import { ResetSuccessComponent } from './core/reset-success/reset-success.component';
import { ResetPasswordContainerComponent } from './core/reset-password-container/reset-password-container.component';
import { ForgotPasswordContainerComponent } from './core/forgot-password-container/forgot-password-container.component';
// import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { RequestAccessContainerComponent } from './core/request-access-container/request-access-container.component';
import { LoginContainerComponent } from './core/login-container/login-container.component';


export const routes: Routes = [
  {
    path: '', canActivate: [AuthGuard, DomainGuard], canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'external', pathMatch: 'full' },
      { path: 'home', component: LoginContainerComponent, data: { module: 'home' } },
      { path: 'search-results/:searchQuery/:selectedStatus', component: SearchResultsComponent },
      {
        path: '', component: DashboardComponent, children: [
          {
            path: 'dashboard', component: DashboardComponent, data: {
              roles: ['Super Admin',
                'Admin Maintenance',
                'Admin Peri Op',
                'Circulating Nurse',
                'Hospital Exec',
                'Hospital IT',
                'Materials Handler',
                'Materials Manager',
                'Nurse Manager',
                'Admin Supply Chain',
                'Materials Admin',
                'Clinical Admin',]
            }
          },
          {
            path: 'dashboard/materials-management', component: MaterialsManagementContainerComponent, data: {
              roles: ['Super Admin',
                'Admin Maintenance',
                'Admin Supply Chain',
                'Hospital Exec',
                'Hospital IT',
                'Materials Handler',
                'Materials Manager',
                'Materials Admin',]
            }
          },
          {
            path: 'dashboard/clinical', component: ClinicalContainerComponent, data: {
              roles: ['Super Admin',
                'Circulating Nurse',
                'Nurse Manager',
                'Hospital Exec',
                'Admin Peri Op',
                'Clinical Admin',]
            }
          }
        ]
      },
      { path: 'customer-support', component: CustomerSupportComponent },
      { path: 'notifications/list', component: NotificationListComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'legal-notice', component: LegalNoticeComponent },
      { path: 'pricing-disclosure', component: PricingDisclosureComponent },
      // { path: 'login', component: LoginComponent, data: { module: 'login' } },
      { path: 'forgot-password', component: ForgotPasswordContainerComponent, data: { module: 'forgot-password' } },

      // { path: 'request-access', component: RequestAccessComponent, data: { module: 'request-access' } },
      // { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'reset-password', component: ResetPasswordContainerComponent },
      { path: 'login', component: LoginContainerComponent, data: { module: 'login' } },
      // { path: 'forgot-password', component: ForgotPasswordComponent, data: { module: 'forgot-password' } },
      { path: 'request-access', component: RequestAccessContainerComponent, data: { module: 'request-access' } },
      // { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'reset-success', component: ResetSuccessComponent },
      { path: 'reset-password/:username/:code/:token', component: ResetPasswordContainerComponent, data: { module: 'confirm-password' } },
      { path: 'password-expired/:username', component: ResetPasswordContainerComponent },
      { path: 'user-setup', component: UserSetupComponent },
      { path: 'complete-registration/:username/:tmppwd/:token/:fname/:lname', component: UserSetupComponent },
      { path: 'complete-registration-notifications/:username/:tmppwd/:token/:fname/:lname', component: UserSetupComponent },
      { path: 'complete-registration-login/:username/:tmppwd/:token/:fname/:lname', component: UserSetupComponent },
      { path: 'dashboard', component: DashboardComponent, data: { module: 'dashboard' } },
      { path: 'control-tower', loadChildren: () => import('./control-tower/control-tower.routes').then(m => m.CONTROL_TOWER_ROUTES) },
      { path: 'inventories', loadChildren: () => import('./inventories/inventories.routes').then(m => m.INVENTORIES_ROUTES) },
      { path: 'pick-request', loadChildren: () => import('./pick-request/pick-request.routes').then(m => m.PICKREQUEST_ROUTES) },
      { path: 'insights', loadChildren: () => import('./insights/insights.routes').then(m => m.INSIGHTS_ROUTES) },
      { path: 'user-management', loadChildren: () => import('./user-management/user-management.routes').then(m => m.USER_MANAGEMENT_ROUTES) },
      { path: 'system-management', loadChildren: () => import('./system-management/system-management.routes').then(m => m.SYSTEM_MANAGEENT_ROUTES) },
      { path: 'ops', loadChildren: () => import('./ops/ops.routes').then(m => m.OPS_ROUTES) },
    ]
  }

];

// export function setupScrollRestoration() {
//   const viewportScroller = inject(ViewportScroller);
//   return () => {
//     window.addEventListener('popstate', () => {
//       viewportScroller.scrollToPosition([0, 0]);
//     });
//   };
// }

// setupScrollRestoration();
