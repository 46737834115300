import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '../../auth/auth.service';
import { StorageService } from '../storage.service';
import { AdamConf } from '@app/app.config';
import { ForgotPasswordAPIResponse } from '../models/forgot-password.model';  // Assuming you have this model

@Component({
  selector: 'app-engine-forgot-password',
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule],
  templateUrl: './engine-forgot-password.component.html',
  styleUrls: ['./engine-forgot-password.component.scss']
})
export class EngineForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  requestSubmitted = false;
  submitted = false;
  errorMessage = '';
  adam_labels: typeof AdamConf;
  requestOrigin: string;
  @Output() notifySignUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() notifySignIn: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly route: Router,
    private readonly authService: AuthService,
    private readonly storageService: StorageService
  ) {}

  ngOnInit() {
    this.requestOrigin = this.storageService.getItem('hostName') ?? '';
    this.adam_labels = AdamConf;
    this.forgotForm = new FormGroup({
      emailID: new FormControl('')
    });
  }

  get f() {
    return this.forgotForm.controls;
  }

  validateForm() {
    let valid = true;
    if (this.forgotForm.value.emailID === '') {
      this.forgotForm.controls.emailID.setErrors({ required: true });
      valid = false;
    }
    if (this.forgotForm.value.emailID !== '') {
      const regEx = new RegExp(/^(?!.*?[._\-@]{2})(?!^\.)(?!^_)(?!^-)(?!.*[-_.]$)[A-Za-z0-9_.-]+@[A-Za-z0-9.]+\.[A-Za-z]+$/);
      if (!regEx.test(this.forgotForm.value.emailID)) {
        this.forgotForm.controls.emailID.setErrors({ pattern: true });
        valid = false;
      }
    }
    return valid;
  }

  onForPassSubmit() {
    this.submitted = true;
    if (!this.validateForm()) {
      return false;
    }

    const params = {
      email: this.forgotForm.value.emailID.toUpperCase(),
      requestOrigin: this.requestOrigin
    };

    this.authService.forgotPassword(params).subscribe({
      next: (response: ForgotPasswordAPIResponse) => {
        if (response.responseCode === '2001') {
          this.requestSubmitted = true;
        } else if (response.responseCode === '4002') {
          this.errorMessage = response.responseMessage;
        } else {
          this.errorMessage = "Operation failed. Please contact administrator!";
        }
      },
      error: () => {
        this.errorMessage = "Operation failed. Please contact administrator!";
      }
    });
  }
 
  resetForm() {
    this.forgotForm.reset();
    this.submitted = false;
    this.requestSubmitted = false;
    this.route.navigate(['login']);
  }

  clearErrors() {
    this.f.emailID.setErrors(null);
    this.submitted = false;
  }

  cancelRequest() {
    this.route.navigate(['home']);
  }

  requestAccess() {
    this.route.navigate(['request-access']);
  }
}