<div class="flex-container">
  <div class="flex-item-left">
    <app-engine-forgot-password></app-engine-forgot-password>
    <div class="flex-item-third">
      <app-footer [customClass]="'forgot-footer'"></app-footer>
    </div>
  </div>
  <div class="flex-item-right">
    <app-search></app-search>
  </div>
</div>