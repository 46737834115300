import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AdamConf } from '../../app.config';
import { UtilityService } from '../../shared/utility.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  public adam_labels = AdamConf;
  public isMobileFlag: boolean = false;
  @Input('isOps') isOps: boolean = false;
  public currentYear: number = 0;
  @Input() customClass: string;
  constructor(
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.isMobileFlag = this.utilityService.isMobile();
    this.adam_labels = AdamConf;
    this.currentYear = (new Date()).getFullYear();
  }

  public navigateToHome(): void {
    if (this.isMobileFlag) {
      this.router.navigate(['inventories/hand-held-scanner/replenishment-summary']);
    } else {
      this.router.navigate(['home']);
     
    }
  }

  public navigateTo(path: string): void {
    this.router.navigate([path]);
  }
}
