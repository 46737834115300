import { Component, importProvidersFrom, OnInit, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AdamConf } from './app.config';
import { AuthService } from './auth/auth.service';
import { GlobalSpinnerComponent } from './core/global-spinner/global-spinner.component';
import { OpsAuthService } from './ops/ops-auth.service';
import { StorageService } from './core/storage.service';
import { CommonModule, isPlatformBrowser } from '@angular/common'; 
import { RouterOutlet } from '@angular/router';
import { LoginComponent } from "./core/login/login.component";
import { MatInputModule } from '@angular/material/input';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
// import { BrowserModule } from '@angular/platform-browser';
import { TokenHandlerInterceptor } from './core/token-handler.interceptor';
import { BaseService } from './../app/core/base.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators'; // Add this import
import { ErrorHandlerInterceptor } from './core/error-handler.interceptor';
import { ChangeDetectorRef } from '@angular/core';
import { PDialogComponent } from './util/p-dialog/p-dialog.component';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { EngineForgotPasswordComponent } from './core/engine-forgot-password/engine-forgot-password.component';
import { ForgotPasswordContainerComponent } from './core/forgot-password-container/forgot-password-container.component';
import { NonceService } from 'src/noonce.service';
import { ScriptLoaderService } from 'src/script-loader.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ CommonModule, HeaderComponent, FooterComponent, MatInputModule, RouterOutlet, PDialogComponent, NgxSpinnerModule ,EngineForgotPasswordComponent,ForgotPasswordContainerComponent],
  providers: [
    // TokenHandlerInterceptor,
    // BaseService,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  activeHeader = true;
  activeFooter = true;
  public globalSpinnerComponent = GlobalSpinnerComponent;
  customObject = 'hospital';
  shouldApplyContainerFluid = false;
  greyBgPages = [
    'dashboard',
    'replenishment/select-unit',
    'expire/select-unit',
    'ops',
    'inventories/inventory-levels',
    'recall/select-unit',
    'select-unit',
    'expire/select-unit-mobile'
  ];
  greybg = false;
  isOpsHeader = false;
  loaderExclusions = AdamConf.LOADER_EXCLUSIONS;
  noHeaderPages = [
    'edit',
    'review',
    'planogram',
    'print-unit',
    'hand-held-scanner/scanner',
    'expire-product-mobile',
    'assessment-tool',
    '/expire/select-unit-mobile',
    '/ops/configurator-summary',
    '/ops/configure-tool',
    'login',
    'home',
    'reset-password',
    'forgot-password',
    'password-expired/',
    'reset-password/',
    'request-access',
    'user-setup',
    'complete-registration/',
    'complete-registration-notifications/',
    'complete-registration-login/',



  ];
  minimalHeaderPages = [
    // 'login',
    // 'forgot-password',
    // 'reset-password',
    // 'reset-success',
    // 'complete-registration',
    // 'complete-registration-topics',
    // 'complete-registration-notifications',
    // 'complete-registration-login',
    // 'request-access',
    // 'reset-password',
    'assessment-tool',
    // 'password-expired',
    'ops',
    'hand-held-scanner/select-unit',
    'expire/select-unit-mobile'
  ];
  searchCounter: number;
  minimalHeader: boolean;
  showLogo: boolean;
  authenticated: boolean;
  opsAuthenticated: boolean;
  searchNav: boolean;
  greyBgCounter;
  printStyle = false;
  currentModule = '';
  minimalHeaderPagesCounter;
  

  constructor(
    private router: Router,
    private authService: AuthService,
    private opsAuthService: OpsAuthService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private nonceService: NonceService,
    private scriptLoader: ScriptLoaderService,
  ) {
      
    this.searchCounter = 0;
    this.minimalHeader = false;
    this.showLogo = false;
    this.authenticated = false;
    this.opsAuthenticated = false;
    this.searchNav = false;
    this.greyBgCounter = 0;
    this.printStyle = false;
    this.currentModule = '';
    this.minimalHeaderPagesCounter = 0;
  
    this.authService.currentUser.pipe(
      mergeMap(data => {
        return of(data); // Use 'of' to wrap the data in an Observable
      })
    ).subscribe(data => {
      if (data) {
        this.authenticated = true;
      } else {
        this.authenticated = false;
      }
    });
    this.opsAuthService.currentUser.subscribe(data => {
      if (data) {
        this.opsAuthenticated = true;
      } else {
        this.opsAuthenticated = false;
      }
    });
  }

  ngOnInit() {

    const nonce = this.nonceService.getNonce();
    document.documentElement.setAttribute('nonce', nonce);
    if (this.authService.getCurrentUser()) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    if (this.opsAuthService.getOpsCurrentUser()) {
      this.opsAuthenticated = true;
    } else {
      this.opsAuthenticated = false;
    }

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.shouldApplyContainerFluid = e.url.includes('reset-password') || e.url.includes('forgot-password') ||  e.url.includes('home') ||   e.url.includes('password-expired') ||   e.url.includes('login') ||   e.url.includes('request-access')||   e.url.includes('complete-registration') ;
        
        if (this.loaderExclusions.some(path => e.url.includes(path))) {
          this.spinner.hide();
        }
        this.searchCounter = 0;
        this.minimalHeaderPagesCounter = 0;
        if (e.url.indexOf('print-unit') !== -1) {
          this.printStyle = true;
        }
        this.noHeaderPages.forEach(element => {
          if (e.url.indexOf(element) !== -1) {
            ++this.searchCounter;
          }
        });
        if (this.searchCounter > 0) {
          this.activeHeader = false;
          this.activeFooter = false;
        } else {
          this.activeHeader = true;
          this.activeFooter = true;
        }

        // Set separate flag for Ops module to hide all header menu options.
        this.isOpsHeader = e.url.indexOf('ops') !== -1 || e.url.indexOf('unauthorized') !== -1;
        this.minimalHeaderPages.forEach(element => {
          if (e.url.indexOf(element) !== -1) {
            ++this.minimalHeaderPagesCounter;
          }
        });
        if (this.minimalHeaderPagesCounter > 0) {
          this.minimalHeader = true;
        } else {
          this.minimalHeader = false;
        }

        if (e.url.indexOf('/home') !== -1) {
          this.showLogo = false;
        } else {
          this.showLogo = true;
        }

        this.greyBgCounter = 0;
        this.greyBgPages.forEach(element => {
          if (element === 'ops') {
            if (e.url === '/ops' || e.url === '/ops/dashboard') {
              ++this.greyBgCounter;
            }
          } else {
            if (e.url.indexOf(element) !== -1) {
              ++this.greyBgCounter;
            }
          }
        });
        if (this.greyBgCounter > 0) {
          this.greybg = true;
        } else {
          this.greybg = false;
        }

        if (e.url.indexOf('/inventories') !== -1 || e.url.indexOf('/pick-request') !== -1) {
          this.currentModule = 'productMangement';
        } else if (e.url.indexOf('/system-management') !== -1) {
          this.currentModule = 'systemManagement';
        } else if (e.url.indexOf('/user-management') !== -1) {
          this.currentModule = 'userManagement';
        } else if (e.url.indexOf('/notifications/list') !== -1) {
          this.currentModule = 'notifications';
        } else {
          this.currentModule = '';
        }      
        this.storageService.setSessionItem('user-navigation', e.url);  
      }     
    });
  }

  updateSearchNav(searchNavVal: boolean) {
    this.searchNav = searchNavVal;
  }

  selectedOption(option: any) {
    this.customObject = option;
  }

  ngafterViewInit() {
    this.cdr.detectChanges();
  }

}
