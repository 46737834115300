import { Component } from '@angular/core';
import { EngineResetPasswordComponent } from '../engine-reset-password/engine-reset-password.component';
import { SearchComponent } from '../search/search.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-reset-password-container',
  standalone: true,
  imports: [EngineResetPasswordComponent,SearchComponent,FooterComponent],
  templateUrl: './reset-password-container.component.html',
  styleUrl: './reset-password-container.component.scss'
})
export class ResetPasswordContainerComponent {

}
