import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { UserSetupComponent } from '../user-setup/user-setup.component';
import { LoginComponent } from '../login/login.component';
import { SearchComponent } from '../search/search.component';
import { RequestAccessComponent } from '../request-access/request-access.component';
import { FooterComponent } from '../footer/footer.component';



@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterModule,SearchBarComponent, UserSetupComponent, LoginComponent, SearchComponent, RequestAccessComponent,FooterComponent],
  templateUrl: './login-container.component.html',
  styleUrl: './login-container.component.scss'
})
  export class LoginContainerComponent {
  }
