<div class="flex-container">
    <div class="flex-item-left">
      <app-request-access></app-request-access>
      <div class="flex-item-third">
        <app-footer></app-footer>
      </div>
    </div>
    <div class="flex-item-right">
      <app-search></app-search>
    </div>
  </div>