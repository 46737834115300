<div class="flex-container">
    <div class="flex-item-left">
        <app-login></app-login>
        <!-- <app-login (notifySignUp)="showSignUp()" [hidden]="!this.isLogin"></app-login> -->
        <!-- <app-request-access (notifySignIn)="showSignIn()" [hidden]="!this.isSignUp"></app-request-access> -->
        <div class="flex-item-third">
            <app-footer></app-footer>
          </div>
    </div>
    <div class="flex-item-right">
        <app-search></app-search>
    </div>
</div>