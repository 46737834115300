<header *ngIf='activeHeader' class="header-cstm">
  <app-header [activeHeader]="activeHeader" [minimalHeader]="minimalHeader" [authenticated]="authenticated" [opsAuthenticated]="opsAuthenticated" [showLogo]="showLogo" [currentModule]="currentModule" [isOpsHeader]="isOpsHeader" (searchNavToggled)="updateSearchNav($event)"></app-header>
</header>
<main class="container-fluid" [ngClass]="{
  'container-fluidd': shouldApplyContainerFluid, 
  'jjgraybg': (greybg),
  'jjfullheight':(!activeFooter && !activeHeader), 
  'topPadding' : (showLogo && !minimalHeader),
  'printPadding': (printStyle),
  'search-nav-visible':searchNav 
}">
  <ngx-spinner bdColor="rgba(255, 255, 255, 0.8)" size="default" color="rgba(255, 0, 0, 0.6)" type="ball-clip-rotate" [fullScreen]="true" 
    [disableAnimation]="true">
  </ngx-spinner>
  <router-outlet></router-outlet>
  <app-p-dialog></app-p-dialog>
</main>
@if (!shouldApplyContainerFluid) {
<footer class="footer">
  <app-footer *ngIf='activeFooter' [isOps]="isOpsHeader"></app-footer>
</footer>
}