<div class="main-div aita-engine">
    <form [formGroup]="requestAccessForm" (ngSubmit)="requestAccessOnSubmit()">
        <div class="container" *ngIf="!showSuccessPage">
            <div class="header header-font">
                <h1 class="header-font">{{adam_labels.user_management_module.reqAccessHeader}}</h1>
            </div>
            <div class="firstnamelabel label-font">
                {{adam_labels.common.firstNameLabel}}
            </div>
            <div class="firstname">
                <mat-form-field appearance="outline">
                    <img matPrefix src="assets/img/UserCircle.svg" class = "prefix-icon" alt="First Name">
                    <input matInput formControlName="name" type="text" id="name" class="fadeIn second" maxlength="100" placeholder="{{adam_labels.common.firstNameLabel}}"
                        autocomplete="off" spellcheck="false">
                </mat-form-field>
            </div>
            <div *ngIf="f.name.errors" class="firstnameerr">
                    <div *ngIf="submitted && f.name.errors.required" class="error-font">
                        <img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.error_messages.firstNameReq}}
                    </div>
                    <div *ngIf="submitted && f.name.errors.pattern" class="error-font">
                        <img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.error_messages.nameValidationError}}
                    </div>
            </div>
            <div class="lastnamelabel label-font">
                {{adam_labels.common.lastNameLabel}} 
            </div>
            <div class="lastname">
                <mat-form-field appearance="outline">
                    <img matPrefix src="assets/img/UserCircle.svg" class = "prefix-icon" alt="Last Name">
                    <input matInput formControlName="lastName" type="text" id="lastName" class="fadeIn second" maxlength="100" placeholder="{{adam_labels.common.lastNameLabel}}"
                        autocomplete="off" spellcheck="false">
                </mat-form-field>   
            </div>
            <div *ngIf="f.lastName.errors" class="lastnameerr">
                <div *ngIf="submitted && f.lastName.errors.required" class="error-font">
                    <img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.error_messages.lastNameReq}}
                </div>
                <div *ngIf="submitted && f.lastName.errors.pattern" class="error-font">
                    <img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.error_messages.lnameValidationError}}
                </div>
            </div>
            <div class="emaillabel label-font">
                {{adam_labels.acount_settings_module.inpEml}}
            </div>
            <div class="email">
                <mat-form-field appearance="outline">
                    <img matPrefix src="assets/img/EnvelopeSimple.svg" class = "prefix-icon" alt="Email">
                    <input matInput formControlName="email" type="text" id="email" width="100%" class="fadeIn second" placeholder="{{adam_labels.acount_settings_module.inpEml}}"
                        maxlength="100" autocomplete="off" spellcheck="false" (ngModelChange)="onInputChange()">
                </mat-form-field>
            </div>
            <div class="emailerr">
                <div *ngIf="f.email.errors" class="error-font">
                    <div *ngIf="submitted && f.email.errors.required" class="error-font">
                        <img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.error_messages.emailRequiredError}}
                    </div>
                    <div *ngIf="submitted && f.email.errors.pattern" class="error-font">
                        <img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.error_messages.emailValidationError}}
                    </div>
                </div>
                <div *ngIf="submitted && emailAlreadyExists" class="error-font">
                    <div><img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.error_messages.emailAlreadyUsed}}</div>
                </div>
            </div>
            <div class="rolelabel label-font">
                {{adam_labels.common.roleLabel}}
            </div>
            <div class="role">
                <p-dropdown formControlName="role" (onChange)="showRolePlaceHolderFn()" class="dropdown-style custom-p-dropdown"
                    [options]="roleList" placeholder={{adam_labels.common.choooseFromList}} optionLabel="roleDesc"></p-dropdown>
            </div>
            <div *ngIf="f.role.errors" class="roleerr">
                <div *ngIf="submitted && f.role.errors.required" class="error-font">
                    <img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.user_management_module.selectRole}}
                </div>
            </div>
            <div  class="captcha">
                <app-captcha (captchaResponseHandler)="handleCaptchaReponse($event)" class="app-captcha"></app-captcha>
            </div>
            <div class="alreadyhaveaccount">
                <span class="regular-font">{{adam_labels.user_management_module.alreadyHaveAccount}}  </span>
                <a (click)="gotoSignIn()" class="bold-black-font">Sign In</a>
            </div>
            <div  class="captchaerr">
                <div *ngIf="submitted && !isCaptchaValidated" class="error-font">
                    <img src="assets/img/XCircle.svg" class="error-icon" alt="Error"> {{adam_labels.error_messages.invalidCaptcha}}
                </div>
            </div>
            <div class="back">
                <a (click)="cancelRequest()" class="regular-font">
                      <img src="assets/img/ArrowLeft.svg" class="button-icon" alt="Cancel">
                      {{adam_labels.common.backBtn}}
                </a>
            </div>
            <div class="signup">
                <button type="submit" class="btn button-primary-large">{{adam_labels.common.signupBtn}}</button>
            </div>  
           
        </div>
    </form>
    <div *ngIf="showSuccessPage" class="responsecontainer">
        <div>
            <img src="assets/img/CheckCircle.svg" class="tick-icon">
        </div>
            <div class="gheader">
                <h1 class="header-font">{{adam_labels.user_management_module.reqAccessSuccessHeader}}</h1>
            </div>
            <div class="message">
                <label
                    class="regular-font">{{adam_labels.user_management_module.reqAccessSccessSubHeader}}
                </label>
            </div>
            <div class="ok">
                <button type="button" (click)="cancelRequest()"
                    class="btn button-primary-medium">{{adam_labels.user_management_module.continueBtn}}</button>
            </div>
        </div>
</div>  
