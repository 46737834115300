import { Component } from '@angular/core';
import { EngineForgotPasswordComponent } from '../engine-forgot-password/engine-forgot-password.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { SearchComponent } from '../search/search.component';
import { FooterComponent } from '../footer/footer.component';
@Component({
  selector: 'app-forgot-password-container',
  standalone: true,
  imports: [EngineForgotPasswordComponent,SearchBarComponent,SearchComponent,FooterComponent],
  templateUrl: './forgot-password-container.component.html',
  styleUrl: './forgot-password-container.component.scss'
})
export class ForgotPasswordContainerComponent {

}
