<div class="main-div aita-engine">
  @if (!requestSubmitted) {
    <div>
      <form [formGroup]="forgotForm" (ngSubmit)="onForPassSubmit()">
        <div class="container">
          <div class="header">
            <h1 class="title">{{adam_labels.user_management_module.passwordForgotHeading}}</h1>
            <p class="description">{{adam_labels.user_management_module.passwordForgotDescription}}</p>
            @if (errorMessage) {
              <div class="error-font">
              <span>{{errorMessage}}</span>
            </div> 
            }
          </div>
          <div class="emaillabel label-font">
           {{adam_labels.common.emailId}}
          </div>
          <div class="email">
            <mat-form-field appearance="outline">
              <img matPrefix src="assets/img/EnvelopeSimple.svg" class="prefix-icon" alt="Email">
              <input matInput
                formControlName="emailID"
                type="email"
                placeholder="{{adam_labels.common.emailId}}"
                [class.has-error]="errorMessage || (submitted && f.emailID.errors)" >
              </mat-form-field>
            </div>
            @if (submitted && f.emailID.errors) {
              <div class="emailerr">
                <div class="error-font">
                  <img src="assets/img/XCircle.svg" class="prefix-icon" alt="Error">
                  
                  @if (submitted && f.emailID.errors?.['required']) {
                    <span>
                      {{adam_labels.error_messages.emailRequiredError}}
                    </span>
                  }
                  @if (submitted && f.emailID.errors?.['pattern']) {
                    <span>
                      {{adam_labels.error_messages.emailValidationError}}
                    </span>
                  }
                </div>
              </div>
            }
            <div class="noaccount">
              <a (click)="requestAccess()" class="cstm-no-accnt">{{adam_labels.user_management_module.reqAccessinSignin}}</a>
            </div>
            <div class="back">
              <button type="button" (click)="resetForm()" class="back-btn">
                <img src="assets/img/ArrowLeft.svg" alt="Back" class="button-icon">
                <span class="cstm-back">{{adam_labels.common.backBtn}}</span>
              </button>
            </div>
            <div class="signin">
              <input type="submit" class="btn button-primary-large margin-left" value="Restore">
            </div>
          </div>
        </form>
      </div>
    }

    @if (requestSubmitted) {
      <div class="success-message">
        <div>
          <img src="assets/img/CheckCircle.svg" class="tick-icon">
        </div>
        <h1 class="title">{{adam_labels.user_management_module.reqAccessSuccessHeader}}</h1>
        <p class="description">{{adam_labels.user_management_module.passwordSuccessMessage}}</p>
        <button type="button" (click)="cancelRequest()" class="restore-btn">
          {{adam_labels.user_management_module.continueBtn}}
        </button>
      </div>
    }
  </div>