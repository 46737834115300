import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from '@app/core/login/login.component';
import { RequestAccessComponent } from '@app/core/request-access/request-access.component';
import { SearchBarComponent } from '@app/core/search-bar/search-bar.component';
import { SearchComponent } from '@app/core/search/search.component';
import { FooterComponent } from '../footer/footer.component';



@Component({
  selector: 'app-request-access-container',
  standalone: true,
  imports: [RouterModule,SearchBarComponent, LoginComponent, SearchComponent, RequestAccessComponent,FooterComponent],
  templateUrl: './request-access-container.component.html',
  styleUrl: './request-access-container.component.scss'
})
export class RequestAccessContainerComponent {

}
