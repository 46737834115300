<div class="main-div aita-engine">
  @if (!resetSuccess) {
  <div>
    <form [formGroup]="resetPwdForm" (ngSubmit)="onsetPwdSubmit()">
      <div class="container">
        <!-- Header Section -->
        <div class="header">
          @if (routeToForgotPwd||routeToPwdExpired){
          <h1 class="title">{{ heading }}</h1>
          <p class="description">{{ description }}</p>
          }
          @if (!routeToForgotPwd && !routeToPwdExpired){
            <h1 class="title">{{adam_labels.user_management_module.setUpPasswordHeader}} {{fname}} {{lname}}.</h1>
            <p class="description">{{adam_labels.user_management_module.setUpPasswordSubHeader1}} {{adam_labels.user_management_module.setUpPasswordSubHeader2}}</p>
            }
        </div>
        <!-- Create Password Field -->
        <div class="passwordlabel label-font">
          {{adam_labels.user_management_module.rstPwdNewlbl}}
        </div>
        <div class="password">
          <mat-form-field class="custom-mat" style="width:100%;" appearance="outline">
            <img matPrefix src="assets/img/Password.svg" class="prefix-icon" alt="Password">
            <input matInput formControlName="createPassword" [type]="hideNewPassword ? 'password' : 'text'"
              placeholder="{{adam_labels.user_management_module.rstPwdNewlbl}}" (keyup)="confirmPwdChk()" maxlength="30" autocomplete="off">
            <img matSuffix [src]="hideNewPassword ? 'assets/img/EyeSlash.svg' : 'assets/img/Eye.svg'"
              class="suffix-icon" alt="Toggle password visibility" (click)="hideNewPassword = !hideNewPassword">
          </mat-form-field>
        </div>
        <!-- Password Policy -->
        @if (showPwdPolicy) {
        <div class="password-policy">
          <div class="regular-font">
            {{adam_labels.user_management_module.passwordPolicy}}
          </div>
        </div>
        }
        <!-- Confirm Password Field -->
        <div class="confirmlabel label-font">
          {{adam_labels.user_management_module.rstPwdlbl}}
        </div>
        <div class="confirm">
          <mat-form-field appearance="outline">
            <img matPrefix src="assets/img/Password.svg" class="prefix-icon" alt="Password">
            <input matInput formControlName="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'"
              placeholder="{{adam_labels.user_management_module.rstPwdlbl}}" (keyup)="confirmPwdChk()" maxlength="30" autocomplete="off">
            <img matSuffix [src]="hideConfirmPassword ? 'assets/img/EyeSlash.svg' : 'assets/img/Eye.svg'"
              class="suffix-icon" alt="Toggle password visibility" (click)="hideConfirmPassword = !hideConfirmPassword">
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        @if (f.createPassword.errors || f.confirmPassword.errors || pswdNoMatchEr) {
        <div class="passworderr">
          @if (f.createPassword.errors?.['required']) {
          <div class="error-font">
            <img src="assets/img/XCircle.svg" class="prefix-icon" alt="Error">
            {{adam_labels.error_messages.passRequired}}

          </div>
          }
          @if (f.confirmPassword.errors?.['required']) {
          <div class="error-font">
            <img src="assets/img/XCircle.svg" class="prefix-icon" alt="Error">
            {{adam_labels.error_messages.confPass}}
          </div>
          }
          @if (pswdNoMatchEr) {
          <div class="error-font">
            <img src="assets/img/XCircle.svg" class="prefix-icon" alt="Error">
            {{adam_labels.error_messages.passNomatch}}
          </div>
          }
        </div>
        }
        @if (resetErrorMsg) {
        <div class="error-font">
          <img src="assets/img/XCircle.svg" class="prefix-icon" alt="Error">
          {{ resetErrorMsg }}
        </div>
        }
        <!-- Action Buttons -->
        @if (routeToPwdExpired) {
        <div class="back">
          <button type="button" (click)="resetForm()" class="back-btn">
            <img src="assets/img/ArrowLeft.svg" alt="Back" class="button-icon">
            <span>{{adam_labels.common.backBtn}}</span>
          </button>
        </div>
        }
        <div class="submit">
          <button type="submit" class="btn button-primary-large">
            {{adam_labels.user_management_module.passChange}}
          </button>
        </div>
      </div>
    </form>
  </div>
  }

  <!-- Success Message -->
  @if (resetSuccess) {
  <div class="success-message">
    <div>
      <img src="assets/img/CheckCircle.svg" class="tick-icon">
    </div>
    <h1 class="title">{{adam_labels.user_management_module.rstSuccess}}</h1>
    <p class="description">{{adam_labels.user_management_module.resetPwdSuccessSubHeader}}</p>
    <button type="button" (click)="cancelRequest()" class="btn button-primary-large">
      {{adam_labels.user_management_module.continueBtn}}
    </button>
  </div>
  }
</div>