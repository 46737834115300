import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SearchBarComponent } from '../search-bar/search-bar.component';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [RouterModule,SearchBarComponent],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit {

  constructor(private router:Router) { }
 

  ngOnInit() {
    // onInit
  }
  
goTologin(){
  this.router.navigate(['login']);
}
}
