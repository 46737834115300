<div class="main-div aita-engine">
  <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" [ngClass]="{'user-setup-form': isUserSetup === true}">
  <div class="container">
    <div class="header header-font">
      @if (!isUserSetup) {
        {{adam_labels.user_management_module.signinHeader}}
      }
      @if (isUserSetup) {
        <h1 class="header-font">{{adam_labels.user_management_module.signinSetupHeader}}</h1>
      }
      @if (isUserSetup) {
        <p class="regular-font">{{adam_labels.user_management_module.signinSetupSubHeader}}</p>
      }
      @if (invalidUserOrPwd) {
        <div class="error-font">
          {{signInErrorMsg}}
        </div>
      }
    </div>
    
    <div class="emaillabel label-font">
      {{adam_labels.acount_settings_module.inpEml}}
    </div>
      <div class="email">
        <div [ngClass]="isUserSetup === true ? 'email-usersetup' : 'mt-style-email'">
          <mat-form-field appearance="outline">
            <img matPrefix src="assets/img/EnvelopeSimple.svg" class = "prefix-icon" alt="Search">
            <input matInput formControlName="email" type="text" id="email" width="100%"
              class="fadeIn second" maxlength="100" autocomplete="off" spellcheck="false" placeholder="{{adam_labels.acount_settings_module.inpEml}}" >
            </mat-form-field>
        </div>
      </div>
      @if (f['email'].errors) {
        <div class="emailerr">
          @if (submitted && f['email'].errors?.['required']) {
            <div class="error-font"><img src="assets/img/XCircle.svg" class="prefix-icon" alt="Error">
              {{adam_labels.error_messages.emailRequiredError}}
            </div>
          }
          @if (submitted && loginForm.controls['email'].dirty && f['email'].errors?.['pattern']) {
            <div class="error-font">
              <img src="assets/img/XCircle.svg" class="prefix-icon" alt="Error">
              {{adam_labels.error_messages.emailValidationError}}
            </div>
          }
        </div>
      }
      <div class="passwordlabel label-font">
        {{adam_labels.user_management_module.loginPwd}}
      </div>
    <div class = "password">
      <mat-form-field appearance="outline">
        <span matPrefix>
          <img src="assets/img/Password.svg" class="prefix-icon" alt="Search">
        </span>
        <input matInput formControlName="password" placeholder="{{adam_labels.user_management_module.loginPwd}}" width="100%"
          class="fadeIn second" maxlength="100" onPaste="return false" autocomplete="off" [type]="hide ? 'password' : 'text'"  >
        <span  matSuffix>
          <img [src]="hide ? 'assets/img/EyeSlash.svg' : 'assets/img/Eye.svg'" class="prefix-icon" alt="Search" (click)="hide = !hide">
        </span>
      </mat-form-field>        
    </div>
    @if (f['password'].errors) {
      <div class="passworderr">
        @if (submitted && f['password'].errors?.['required']) {
          <div class="error-font"><img src="assets/img/XCircle.svg" class="prefix-icon" alt="Error"> Password is required.</div>
        }
        
      </div>
    }
    <div class="noaccount">
      <div [ngClass]="isUserSetup === true ?'mt-buttons-user-setup':'mt-buttons'">
        @if (!isUserSetup) {
            <a (click)="requestAccess()" class="bold-black-font">{{adam_labels.user_management_module.reqAccessinSignin}}</a>
        }
      </div>
    </div>
    <div class="forgetpassowrd">
      <span class="regular-font">{{adam_labels.user_management_module.forgotPwd}}  </span>
      <a (click)="gotoForgotPwd()" class="bold-black-font">Restore</a>
    </div>
   
    <div class="signin">
      <div [ngClass]="isUserSetup === true ?'mt-buttons-user-setup':'mt-buttons'">
          <input type="submit" class="btn button-primary-large ml-5 margin-left" value={{adam_labels.user_management_module.signinButton}}>
      </div>
    </div>
  </div>
</form>
</div>
