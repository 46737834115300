import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SearchServiceService } from './search-service.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { CommonModule, Location } from '@angular/common';
import { StorageService } from '../storage.service';
import { AdamConf } from '@app/app.config';
import { AutoComplete, AutoCompleteModule } from 'primeng/autocomplete';
import { SearchBarAutoCompleteApiInfo, AutoCompleteSuggestionInfo, AutoCompleteEventInfo } from '../models/header.model';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { SearchResultsComponent } from '../search-results/search-results.component';
interface SearchQueryData {
  fullDescription?: string;  
  productCode?: string;      
  [key: string]: any;        
}

interface SearchQuery {
  data?: string | SearchQueryData; 
  source?: 'response' | 'history'; 
  query?: string;                  
  [key: string]: any;              
}

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [AutoCompleteModule, DialogModule, FormsModule, CommonModule,SearchResultsComponent ], 
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

  @ViewChild('searchAutocomplete', { static: false }) autoComplete: AutoComplete;
  @Input('toggleSearchBar') toggleSearchBar: boolean;

  searchQuery: string | SearchQuery | null = null; 
  public showAutoCompleteDropdown: boolean = false;


  searchHistory: any[] = [];
  matchedSearchHistory = [];
  prodList: SearchBarAutoCompleteApiInfo = {
    responseCode: '',
    responseMessage: '',
    responseData: []
  };
  selectedStatus: string;
  lastRequestedQuery: string = null;
  predictionSubscription: Subscription;
  showMultitenanceError = false;
  adam_labels: any;
  statusList: any[] = [
    { label: 'FILTER BY', value: 'All', optionLabel: 'Reset', selected: true },
    { label: 'EXPIRED', value: 'Expired', optionLabel: 'Expired', selected: false },
    { label: 'RECALLED', value: 'Recalled', optionLabel: 'Recalled', selected: false }];
  autoCompleteSuggestionList: AutoCompleteSuggestionInfo[];
  public showDropdown: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly searchServiceService: SearchServiceService,
    private readonly authService: AuthService,
    private readonly location: Location,
    private readonly storageService: StorageService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && val.url.indexOf('search-results') === -1) {
        if (this.autoComplete) {
          this.autoComplete.inputEL.nativeElement.value = '';
          this.searchQuery = '';
        }
      }
    });
  }

  public ngOnInit(): void {
    this.adam_labels = AdamConf;
  
    if (this.router.url.split('/')[1] === 'search-results') {
      const searchData = decodeURIComponent(this.router.url.split('/')[2]);
      this.searchQuery = null; // Explicitly set to null
      this.selectedStatus = this.router.url.split('/')[3];
      this.populateProductList({ query: searchData });
    } else {
      this.searchQuery = null; 
      this.selectedStatus = this.statusList[0].value;
    }
  
    const history = this.storageService.getItem('searchHistory');
    if (history) {
      this.searchHistory = JSON.parse(history);
    }
  
    this.predictionSubscription = this.searchServiceService.getSearchObs().subscribe(data => {
      if (data?.data && data !== 1) {
        setTimeout(() => {
          this.searchQuery = null; // Ensure query is cleared
        }, 0);
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.indexOf('search-results') === -1) {
        this.searchQuery = null;
        this.autoCompleteSuggestionList = [];
        if (this.autoComplete) {
          this.autoComplete.hide();
        }
      }
    });
  }

  public showSuggestions(): void {
    if (this.storageService.getItem('hostName')) {
      // Reset to empty string if searchQuery is null or undefined
      if (this.searchQuery === null || this.searchQuery === undefined) {
        this.searchQuery = '';
      }
  
      if (typeof this.searchQuery === 'string') {
        if (this.searchQuery.trim().length) {
          this.updateSuggestions({ query: this.searchQuery });
          if (this.autoCompleteSuggestionList.length > 0) {
            this.autoComplete.show();
          }
        }
      } else if (this.searchQuery && typeof this.searchQuery === 'object') {
        // ... rest of the method remains the same ...
      } else {
        this.searchQuery = '';
      }
    } else {
      this.showMultitenanceError = true;
    }
  }


  

public navigateToResults(): void {
  
  // Hide the autocomplete dropdown
  if (this.autoComplete) {
    this.autoComplete.hide();
  }
  this.autoCompleteSuggestionList = [];
  if (this.searchQuery) {
    let searchData;
    if (typeof this.searchQuery === 'string') {
      searchData = this.searchQuery;
    } else if (this.searchQuery.data) {
      if (typeof this.searchQuery.data === 'string') {
        searchData = this.searchQuery.data;
      } else if (this.searchQuery.source === 'history') {
        searchData = this.searchQuery.query;
      } else {
        searchData = this.searchQuery.data.productCode;
      }
    } else {
      searchData = this.searchQuery.query;
    }

    // Clear the search query and input field completely

    this.searchQuery = null;
    if (this.autoComplete && this.autoComplete.inputEL) {
      this.autoComplete.inputEL.nativeElement.value = '';
    }

    if (this.router.url.split('/')[1] === 'search-results') {
      const url = this.router.createUrlTree(['search-results', searchData, this.selectedStatus]).toString();
      this.location.go(url);
      this.searchServiceService.setSearchObs({ data: searchData, status: this.selectedStatus, query: searchData });
    } else {
      this.router.navigate(['/search-results/' + searchData + '/' + this.selectedStatus]);
    }
  }
  
}


  

  private createSuggestionList(event: AutoCompleteEventInfo): void {
    this.autoCompleteSuggestionList = [];
    if (this.prodList.responseData && this.prodList.responseData.length) {
      const props = ['productCode', 'productDescription', 'absorbability', 'needlePointType', 'reloadColor', 'needleName'];
      this.prodList.responseData.forEach((d) => {
        d.fullDescription = '';
        for (let index = 0; index < props.length; index++) {
          const prop = props[index];
          if (d[prop]) {
            if (index !== 0) {
              d.fullDescription = d.fullDescription.concat(' ');
            }
            d.fullDescription = d.fullDescription.concat(d[prop].toLowerCase());
          }
        }
        return d;
      });
      this.createSuggestionAutocomplete(event);
    }
  }

  private createSuggestionAutocomplete(event: AutoCompleteEventInfo): void {
    for (const value of this.prodList.responseData) {
      const currentItem: AutoCompleteSuggestionInfo = {
        source: 'response',
        data: value,
        query: event.query
      };
      this.autoCompleteSuggestionList.push(currentItem);
    }
    if (this.router.url.split('/')[1] === 'search-results' && JSON.parse(this.storageService.getItem('searchHistory'))) {
      this.searchHistory = JSON.parse(this.storageService.getItem('searchHistory'));
    }

    if (this.searchHistory.length) {
      this.matchedSearchHistory = [];
      for (const value of this.searchHistory) {
        if (value.data.toLowerCase().indexOf(event.query.toLowerCase()) !== -1) {
          this.matchedSearchHistory.push({
            source: 'history',
            data: { fullDescription: value.data },
            query: value.data
          });
        }
      }
    }
    this.matchedSearchHistory.sort((a, b) => a.data.fullDescription.localeCompare(b.data.fullDescription));
    this.autoCompleteSuggestionList = [...this.matchedSearchHistory.slice(0, 3), ...this.autoCompleteSuggestionList];
    this.autoCompleteSuggestionList = this.autoCompleteSuggestionList.filter((suggestionList, index, self) =>
      index === self.findIndex((t) => (t.data.fullDescription.toLowerCase() === suggestionList.data.fullDescription.toLowerCase())));
    this.autoComplete.show();
  }

  public updateSuggestions(event: AutoCompleteEventInfo): void {
    this.populateProductList(event);
    
    if (!event.query) {
        this.searchQuery = '';
    }
}

  private populateProductList(event: AutoCompleteEventInfo): void {
    let hostName = null;
    if (event.query.replace(/[^a-z0-9-,. ]/gi, '') !== '') {
      const userData = this.authService.getUserData();
      if (userData === null || userData === undefined) {
        hostName = this.storageService.getItem('hostName');
        this.searchServiceService.getProductsForAutoComplete(event.query, hostName)
          .subscribe((products: SearchBarAutoCompleteApiInfo) => {
            if (products.responseCode === '2001') {
              this.prodList = products;
              this.createSuggestionList(event);
            } else if (products.responseCode === '4002') {
              // backend error
              this.prodList = {
                responseCode: '',
                responseMessage: '',
                responseData: []
              };
            }
          });
      } else {
        this.searchServiceService.getProductsForAutoCompleteAuthorized(event.query)
          .subscribe((products: SearchBarAutoCompleteApiInfo) => {
            if (products.responseCode === '2001') {
              this.prodList = products;
              this.createSuggestionList(event);
            } else if (products.responseCode === '4002') {
              // backend error
              this.prodList = {
                responseCode: '',
                responseMessage: '',
                responseData: []
              };
            }
          });
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.predictionSubscription) {
      this.predictionSubscription.unsubscribe();
    }
  }

}
